
export const homeObjOne = {
  primary: false,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  topLine: "Worship with us today at",
  headline:
    "Welcome to Mountain of Fire and Miracles Ministries, Stoke-On-Trent, United Kingdom",
  description: "Surely the Lord is in this place...(Genesis 28:16)",
  buttonLabel: "Connect with us Today",
  imgStart: "end",
  img: require("../../images/img_design3.jpg").default,
  alt: "glorious service",
  start: '',
};



export const homeObjThree = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: "Bible Study",
  headline: "Explore the Scriptures",
  description: `"Study to show thyself approved unto God, a workman that needeth not to be ashamed, rightly dividing the word of truth - 2 Timothy 2:15". Join us as we delve deep into the Scriptures and explore the richness of God's Word during our weekly Bible study sessions...`,
  buttonLabel: "Read More",
  imgStart: "start",
  img: require("../../images/img_design2.jpg").default,
  alt: "Vault",
  start: "true",
};


export const homeObjFour = {
  primary: false,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  topLine: "Prayer Hour",
  headline: "Join Our Prayer Hour",
  description: `Join us for our weekly Prayer Hour, where we come together to seek God's presence and intercede for our needs and the needs of others. Prayer Hour is held every Tuesday between 10pm - 11pm and is open to all.`,
  buttonLabel: "Join Now",

  imgStart: "",
  img: require("../../images/img_design1.jpg").default,
  alt: "Vault",
  start: "true",
};


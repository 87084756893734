import React from "react";
import { Button } from "../../globalStyles";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import { MdLocationOn, MdCall } from "react-icons/md";

import {
  FooterContainer,
  FooterSubscription,
  FooterSubText,
  FooterSubHeading,
  Form,
  FormInput,
  FooterSubHeadingdiv,
  FooterContainerWrapper,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  SocialIcon,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
  FootersubTextWrapper,
} from "./Footer.elements";

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <FooterContainer>
      <FooterContainerWrapper>
        <FooterSubscription>
          <SocialLogo to='/'>
            <SocialIcon />
          </SocialLogo>
          <FooterSubHeading>
            <FooterSubHeadingdiv>
              <MdLocationOn />
              <div style={{ paddingLeft: '10px' }}>
                <b> Address: </b>
                <p>
                  Fenn House, Duke Street, Fenton, Stoke-on-Trent, ST4 3NR,
                  England, United Kingdom
                </p>
              </div>
            </FooterSubHeadingdiv>
          </FooterSubHeading>
          <FooterSubHeading>
            <FooterSubHeadingdiv>
              <MdCall />

              <div style={{ paddingLeft: '10px' }}>
                <b>Telephone:</b>
                <span style={{ paddingLeft: '10px' }}>
                  <p>+44 7466647095, +44 7778989603 </p>
                </span>
                <br />
              </div>
            </FooterSubHeadingdiv>
          </FooterSubHeading>
        </FooterSubscription>
        <FootersubTextWrapper>
          <FooterSubText>
            Subscribe to stay up to date – MFM Stoke On Trent
          </FooterSubText>
          <Form>
            <FormInput name='email' type='email' placeholder='Your Email' />
            <Button fontBig>Subscribe</Button>
          </Form>
        </FootersubTextWrapper>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkTitle>Useful Links</FooterLinkTitle>
            <FooterLinkItems>
              <FooterLink to='/about-us'>→ About Us</FooterLink>
              <FooterLink to='/contact'>→ Contact Us</FooterLink>
              <FooterLink to='/donate'>→ Donate</FooterLink>
              <FooterLink to='/connect'>→ Livestream</FooterLink>
              <FooterLink to='/prayer'>→ Prayer Points</FooterLink>
              <FooterLink to='/contact'>→ Testimonies</FooterLink>

              {/* <FooterLink to='/'>&gt; Gallery</FooterLink>
           <FooterLink to='/'>&gt; Gallery</FooterLink> */}
              <FooterLink to='/contact'>→ Prayer Request</FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>
      </FooterContainerWrapper>

      <SocialMedia>
        <SocialMediaWrap>
          <WebsiteRights>
            Mfm Stoke United Kingdom &copy; {currentYear}
          </WebsiteRights>
          <SocialIcons>
            <SocialIconLink
              href={'https://www.facebook.com/profile.php?id=61553444297441'}
              target='_blank'
              aria-label='Facebook'
            >
              <FaFacebook />
            </SocialIconLink>
            <SocialIconLink
              href={'https://www.instagram.com/mfmstoke_on_trent/'}
              target='_blank'
              aria-label='Instagram'
            >
              <FaInstagram />
            </SocialIconLink>

            {/* <SocialIconLink
              href={
                "https://www.youtube.com/channel/UCgE8yDGVJvNEdRuxxHohPsQ?view_as=subscriber"
              }
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Youtube"
            >
              <FaYoutube />
            </SocialIconLink> */}
            <SocialIconLink
              href='https://twitter.com/MfmSOT'
              target='_blank'
              aria-label='Twitter'
            >
              <FaTwitter />
            </SocialIconLink>
            <SocialIconLink
              href='/'
              target='_blank'
              aria-label='LinkedIn'
            ></SocialIconLink>
          </SocialIcons>
        </SocialMediaWrap>
      </SocialMedia>
    </FooterContainer>
  );
}

export default Footer;

import React from 'react'
import PropTypes from "prop-types";
import "./Connect.css"
import { Container } from "../../globalStyles";
const data = [
  {
    id: 0,
    name: "SUNDAY SERVICE",
    link: "https://www.youtube.com/watch?v=Zf69Ojcmnu8",
  },
  {
    id: 1,
    name: "PMCH",
    link: "https://www.youtube.com/watch?v=Zf69Ojcmnu8",
  },

  {
    id: 3,
    name: "FACEBOOK",
    link: "https://www.facebook.com/DR.OLUKOYA",
  },
  {
    id: 4,
    name: "INSTAGRAM",
    link: "https://www.instagram.com/official_dkolukoya/live/",
  },
];



const Connect = () => (
  <Container>
    <br />
    <div className="video-responsive">
      <iframe
        max-width="560px"
        height="400"
        src="https://www.youtube.com/embed/ljOcS3fjip4"
        title="MFM Television"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
    </div>

    <h1 className="header"> LIVE CHANNELS</h1>
    <br />
    <ul className="lists">
      {data.map((data) => {
        return (
          <a href={data.link} target="_blank" rel="noreferrer">
            <button className="eachlink" key={data.id}>
              {data.name}
            </button>
          </a>
        );
      })}
    </ul>
    <div className="btn">
      <a
        href="https://mixlr.com/mfmonlineradio"
        target="_blank"
        rel="noreferrer"
        className="single_btn"
      >
        <button className="eachlink">AUDIO ONLY</button>{" "}
      </a>
    </div>
  </Container>
);

Connect.propTypes = {
  embedId: PropTypes.string.isRequired
};

export default Connect;


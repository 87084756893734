import React from "react";
import {
  Image,
  GiveSec,
  Givewrapper,
  Givetitle,
  GiveSecTitle,
Button,
  GiveContainer,
} from "./Donate.element";

import img from "../../images/image.png";

const Donate = () => {
  return (
    <GiveContainer>
      <Givetitle>
        We appreciate your donation towards the advancement of the Gospel.
      </Givetitle>
      <Givewrapper>
        <div>
          <Image alt={""} src={img} />
        </div>
        <GiveSec>
          <div>
            <GiveSecTitle>For Donations or inquiries contact us via :</GiveSecTitle>
            <a href="/contact">
              <Button primary fontBig type="button">
                Submit
              </Button>
            </a>
          </div>
        </GiveSec>
      </Givewrapper>
    </GiveContainer>
  );
};

export default Donate;

import styled, { keyframes } from "styled-components";
import { Container } from "../../globalStyles";
import { Link } from "react-router-dom";
import img from "../../images/strokeLogo.png";

export const Nav = styled.nav`
  background: var(--clr-primary-1);
  height: 80px;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 999;
`;

export const NavbarContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding-right: 10px;
  padding-left: 10px;
  ${Container}
`;

export const NavLogo = styled(Link)`
  color: var(--clr-primary-2);
  justify-self: flex-start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
`;

export const NavIcon = styled.img.attrs({
  src: `${img}`,
})`
  width: 100%;
  max-width: 200px;
  height: 50px;

  @media screen and (min-width: 960px) {
    width: 200px;
    height: 60px;
  }
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 960px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.5rem;
    cursor: pointer;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;

  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: ${({ click }) => (click ? 0 : "-100%")};
    opacity: 1;
    transition: all 0.5s ease;
    background: var(--clr-deep-red);
  }
`;

export const NavItem = styled.li`
  height: 80px;
  display: flex;
  border-bottom: 2px solid transparent;

  @media screen and (max-width: 960px) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const NavLinks = styled(Link)`
  color: var(--clr-primary-2);
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;

  &:hover {
    padding-top: 1rem;
    border-bottom: 2px solid var(--clr-deep-red);
  }

  @media screen and (max-width: 960px) {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    &:hover {
      color: var(--clr-primary-1);
      transition: all 0.1s ease;
      border: none;
    }
  }
`;

const moveBorder = keyframes`
  0% {
    transform: translateX(0) translateY(0);
  }
  25% {
    transform: translateX(5px) translateY(5px);
  }
  50% {
    transform: translateX(-5px) translateY(-5px);
  }
  75% {
    transform: translateX(5px) translateY(-5px);
  }
  100% {
    transform: translateX(0) translateY(0);
  }
`;

export const Button = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${({ primary }) =>
    primary ? "var(--clr-deep-red)" : "var(--clr-primary-1)"};
  color: ${(props) => (props.primary ? "white" : "palevioletred")};
  width: 95%;
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  margin: 0.5rem auto;
  padding: 0.25em 1em;
  float: right;
  border: 2px solid white;
  border-radius: 3px;
  position: relative; /* Ensure the animation is relative to the button */
  overflow: hidden; /* Hide overflowing border animation */

  animation: ${moveBorder} 3s infinite; /* Apply the animation to the button */

  &:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    background-color: ${({ primary }) => (primary ? "#c934b3" : "#4B59F7")};
    border-color: white; /* Reset border color on hover */
    animation-play-state: paused; /* Pause the animation on hover */
  }

  @media screen and (min-width: 768px) {
    width: 98%;
    margin: 0 auto;
  }
`;
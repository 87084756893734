import React from 'react'
import "./Prayer.css";
const Prayer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="wrapper">
      <h2>MFM Stoke-On-Trent DOWNLOADS</h2>
      <div className="content">
        <p>
          Hi there! During some MFM services, prayer points are usually handed
          out. To ensure everyone has a copy of these prayers and other
          resources no matter your location, we will be adding them here for
          download, FREE!!!
        </p>
      </div>

      <h3>Keep the Fire Burning with MFM Downloads</h3>
      <section className="prayerflex">
        <div className="prayersec">
          <h4>
            {" "}
            CLICK HERE TO DOWNLOAD THE PROPHETIC OUTLOOK FOR THE YEAR{" "}
            {currentYear}
          </h4>
          <a
            href="https://www.mountainoffire.org/resources/prayer-points"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button> Download</button>
          </a>
        </div>
        <div className="prayersec">
          <h4> CLICK HERE TO DOWNLOAD POWER MUST CHANGE HANDS PRAYERS</h4>
          <a
            href="https://www.mountainoffire.org/pmch"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button> Download</button>{" "}
          </a>
        </div>
      </section>
    </div>
  );
}

export default Prayer
import { Carousel } from 'react-carousel-minimal';
import First from '../Gallery/Gallery_images/img_design4.jpg';
import Second from '../Gallery/Gallery_images/propheticyear.jpeg';
import Third from '../Gallery/Gallery_images/prayerImage1.jpeg';
import Fourth from '../Gallery/Gallery_images/prayerImage2.jpeg';
import Fifth from '../Gallery/Gallery_images/prayerImage3.jpeg';
import Sixth from '../Gallery/Gallery_images/prayerImage4.jpeg';
import Seventh from '../Gallery/Gallery_images/prayerImage5.jpeg';
import Eighth from '../Gallery/Gallery_images/img_design8.jpeg';
import Ninth from '../Gallery/Gallery_images/img_design1.jpg';
import Tenth from '../Gallery/Gallery_images/img_design2.jpg';
import Eleventh from '../Gallery/Gallery_images/img_design3.jpg';
import Twelve from '../Gallery/Gallery_images/img_design7.jpg';
import Thirteen from '../Gallery/Gallery_images/img_design5.jpg';
import Fourteen from '../Gallery/Gallery_images/img_design6.jpg';
import Seventeen from '../Gallery/Gallery_images/img_design9.jpeg';
import Eighteen from '../Gallery/Gallery_images/img_design10.jpeg';
import Nineteen from '../Gallery/Gallery_images/img_design11.jpeg';
import Twenty from '../Gallery/Gallery_images/img_design12.jpeg';
import TwentyOne from '../Gallery/Gallery_images/img_design13.jpeg';
import TwentyTwo from '../Gallery/Gallery_images/img_design15.jpeg';
import TwentyThree from '../Gallery/Gallery_images/img_design16.jpg';
import TwentyFour from '../Gallery/Gallery_images/img_design17.jpg';
import TwentyFive from '../Gallery/Gallery_images/img_design18.jpg';
import TwentySix from '../Gallery/Gallery_images/img_design19.jpeg';
import TwentySeven from '../Gallery/Gallery_images/img_design20.jpeg';
import TwentyEight from '../Gallery/Gallery_images/img_design21.jpeg';
import TwentyNine from '../Gallery/Gallery_images/img_design22.jpeg';
import Thirty from '../Gallery/Gallery_images/img_design23.jpeg';
import ThirtyOne from '../Gallery/Gallery_images/img_design24.jpeg';
import ThirtyTwo from '../Gallery/Gallery_images/img_design25.jpeg';
import ThirtyThree from '../Gallery/Gallery_images/img_design26.jpeg';
import ThirtyFour from '../Gallery/Gallery_images/img_design27.jpeg';
import ThirtyFive from '../Gallery/Gallery_images/img_design35.jpg';
import ThirtySix from '../Gallery/Gallery_images/img_design36.jpg';
import ThirtySeven from '../Gallery/Gallery_images/img_design37.jpg';
import ThirtyEight from '../Gallery/Gallery_images/img_design38.jpg';
import ThirtyNine from '../Gallery/Gallery_images/img_design39.jpg';
import Forthy from '../Gallery/Gallery_images/img_design40.jpg';
import './GalleryPage.css';

function GalleryPage() {
  const data = [
    { image: First },
    { image: Ninth },
    { image: Tenth },
    { image: Eleventh },
    { image: Twelve },
    { image: Second },
    { image: Third },
    { image: Fourth },
    { image: Fifth },
    { image: Sixth },
    { image: Seventh },
    { image: Eighth },
    { image: Thirteen },
    { image: Fourteen },
    { image: Seventeen },
    { image: Eighteen },
    { image: Nineteen },
    { image: Twenty },
    { image: TwentyOne },
    { image: TwentyTwo },
    { image: TwentyThree },
    { image: TwentyFour },
    { image: TwentyFive },
    { image: TwentySix },
    { image: TwentySeven },
    { image: TwentyEight },
    { image: TwentyNine },
    { image: Thirty },
    { image: ThirtyOne },
    { image: ThirtyTwo },
    { image: ThirtyThree },
    { image: ThirtyFour },
    { image: ThirtyFive },
    { image: ThirtySix },
    { image: ThirtySeven },
    { image: ThirtyEight },
    { image: ThirtyNine },
    { image: Forthy },
  ];

  const captionStyle = {
    fontSize: '2em',
    fontWeight: 'bold',
  };

  return (
    <>
      <div className='gallery_wrapper' style={{ textAlign: 'center' }}>
        <h2 className='gallery_h2'>MFM WESTFALEN BILLBOARD</h2>
        <Carousel
          className='carousel'
          data={data}
          time={2000}
          width='750px'
          height='450px'
          captionStyle={captionStyle}
          radius='10px'
          automatic={true}
          dots={true}
          pauseIconColor='white'
          pauseIconSize='40px'
          slideBackgroundColor='#f36e57'
          slideImageFit='contain'
          thumbnails={true}
          showNavBtn={false}
          thumbnailWidth='100px'
          style={{
            textAlign: 'center',
            maxWidth: '600px',
            maxHeight: '800px',
            margin: '10px auto',
          }}
        />
      </div>
    </>
  );
}

export default GalleryPage;

import First from "../assets/First.jpeg";
import Second from "../assets/Second.jpg";
import Fourth from "../assets/Fourth.jpeg";
import Third from "../assets/Third.jpg";
import Fifth from "../assets/Fifth.jpg";
import Sixth from "../assets/Sixth.jpg";
import Seventh from "../assets/Seventh.jpg";
import Eighth from "../assets/Eighth.jpg";
import Nineth from "../assets/Nineth.jpeg";
import Tenth from "../assets/Tenth.jpeg";
import mummy from "../assets/mummGO.jpg";
import mannawater from "../assets/MANNA.jpeg";

const Data = [
  { urls: First },
  { urls: Second },
  { urls: Fourth },
  { urls: Sixth },
  { urls: Eighth },
  { urls: mannawater },
  { urls: Third },
  { urls: Fifth },
  { urls: Seventh },
  { urls: Nineth },
  { urls: Tenth },
  { urls: mummy },
];

export default Data;
